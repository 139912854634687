import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet que canvia el color depenent del grau d’humitat, de groc més torrat al centre a blanc argilós. Tant el peu com el capell presenten la mateixa coloració. El peu és esvelt, un poc fibrós i llis. El capell de 2-5 cm de diàmetre és convex al principi, després pla i normalment ondulat i deprimit a la part central. Té nombroses làmines, atapeïdes, estretes, blanques o un poc groguenques i un poc decurrents. Les espores són blanques en massa, el·líptiques, de 5-6 x 2-3 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      